import React, { useState, useEffect, useRef } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import CustomRedirect from './CustomRedirect';

/* ---Auth--- */
import AuthPage from '../pages/AuthPage/AuthPage';
import ForgotPasswordPage from '../pages/AuthPage/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from '../pages/AuthPage/ResetPasswordPage/ResetPasswordPage';
import ActivatePage, {
  ActivateRedirect,
} from '../pages/AuthPage/ActivatePage/ActivatePage';
import SetMultiFactorPage, {
  SetMultiFactorRedirect,
} from '../pages/AuthPage/SetMultiFactorPage/SetMultiFactorPage';

/* ---Dashboard--- */
import DashboardPage from '../pages/DashboardPage/DashboardPage';

/* ---Campaigns--- */
import CampaignsPage from '../pages/CampaignsPage/CampaignsPage';
import CampaignDetailPage from '../pages/CampaignsPage/CampaignDetailPage/CampaignDetailPage';
import CampaignReportPage from '../pages/CampaignsPage/CampaignEditPage/CampaignReportPage/CampaignReportPage';
import CampaignAddPageDeprecated from '../pages/CampaignsPage/CampaignAddPage/CampaignAddPage';
import CampaignEditPageDeprecated from '../pages/CampaignsPage/CampaignEditPage/CampaignEditPage';

/* ---Campaign Terms--- */
import CampaignTermsListPage from '../pages/SettingsPage/CampaignTermsListPage/CampaignTermsListPage';
import CampaignTermsEditPage from '../pages/SettingsPage/CampaignTermsEditPage/CampaignTermsEditPage';

/* ---Users--- */
import UsersPage from '../pages/UsersPage/UsersPage';
import UserEditPage from '../pages/UsersPage/UserEditPage/UserEditPage';

/* ---Companies--- */
import CompaniesPage from '../pages/CompaniesPage/CompaniesPage';
import CompanyDetailsPage from '../pages/CompaniesPage/CompanyDetailsPage/CompanyDetailsPage';

/* ---Codes--- */
import CodesPage from '../pages/CodesPage/CodesPage';

/* ---Providers--- */
import ProviderCarouselImagesPage from '../pages/ProviderCarouselImagesPage/ProviderCarouselImagesPage';
import ProviderDetailsPage from '../pages/ProvidersPage/ProviderDetailsPage/ProviderDetailsPage';
import ProvidersPage from '../pages/ProvidersPage/ProvidersPage';

/* ---Connections--- */
import ConnectionsPage from '../pages/ConnectionsPage/ConnectionsPage';
import ConnectionDetailPage from '../pages/ConnectionsPage/ConnectionDetailPage/ConnectionDetailPage';

/* ---Brands--- */
import BrandsPage from '../pages/BrandsPage/BrandsPage';

/* ---Settings--- */
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import SignUpPage from '../pages/SignUpPage/SignUpPage';

/* ---Terms & Conditions--- */
import TermsPage from '../pages/TermsPage/TermsPage';

/* ---Other--- */
import Navbar from '../components/Navbar/Navbar';
import ContactUsPage from '../pages/ContactUsPage/ContactUsPage';

const MainRoute = ({
  setPassword,
  setResetPassword,
  setMultiFactor,
  isAuthenticated,
  isAdmin,
  isBountyAdmin,
  isCompanyAdmin,
  isCompanyUser,
  isAdvertisingPartner,
  isProviderPartner,
}) => {
  /**
   * IMPORTANT!
   *  Since routes are being re-designated this will
   *  cause a reset of any existing component state
   *  whilst on any individual page during the transition
   */

  const navigate = useNavigate();
  const hadDashboardAccessRef = useRef(null);
  const [initialPath] = useState(window.location.pathname);
  const [hasDashboardAccess, setHasDashboardAccess] = useState(false);

  useEffect(() => {
    setHasDashboardAccess(
      isAdmin ||
        isBountyAdmin ||
        ((isProviderPartner || isAdvertisingPartner) &&
          (isCompanyAdmin || isCompanyUser)),
    );
  }, [
    isAdmin,
    isBountyAdmin,
    isProviderPartner,
    isCompanyAdmin,
    isCompanyUser,
    isAdvertisingPartner,
  ]);

  /**
   * Redirect the user to the dashboard
   *  if additional data was necessary to
   *  enable this routing. The user may
   *  see the campaign list temporarily.
   */
  useEffect(() => {
    /* Only auto-redirect to dash once per tab session */
    const initialDashboard = initialPath === '/dashboard';
    if (sessionStorage.dashboardRedirect && !initialDashboard) {
      return;
    }

    const hasDA = hasDashboardAccess;
    const hadDARef = hadDashboardAccessRef;
    if (hasDA && hadDARef.current !== hasDA) {
      sessionStorage.dashboardRedirect = 1;
      navigate('/dashboard');
    }

    hadDARef.current = hasDA;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDashboardAccess, navigate]);

  const unAuthenticatedRoutes = (
    <>
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/activate" element={<ActivatePage />} />
      <Route path="/terms-and-conditions" element={<TermsPage />} />
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route
        path="*"
        element={
          <Routes>
            <Route path="*" element={<Navigate to="/auth" replace />} />
            <Route path="/reset-password/*" element={<ResetPasswordPage />} />
          </Routes>
        }
      />
    </>
  );

  const lowLevelAuthenticatedRoutes = (
    <>
      {/* ---Dashboard--- */}
      {hasDashboardAccess && (
        <Route path="/dashboard" element={<DashboardPage />} />
      )}

      {/* ---Campaigns--- */}
      <Route path="/campaigns/*" element={<CampaignsPage />} />
      <Route path="/campaigns/add" element={<CampaignDetailPage />} />
      <Route path="/campaigns/edit/:id/*" element={<CampaignDetailPage />} />
      <Route
        path="/campaigns/edit/:id/report"
        element={<CampaignReportPage />}
      />

      {/* DEPRECATED TEMPLATES for comparison testing only */}
      <Route path="/campaigns-d/add" element={<CampaignAddPageDeprecated />} />
      <Route
        path="/campaigns-d/edit/:id/*"
        element={<CampaignEditPageDeprecated />}
      />
      {/* END DEPRECATED TEMPLATES */}

      {/* ---Users--- */}
      <Route path="/users/edit/:id" element={<UserEditPage />} />

      {/* ---Terms & Conditions--- */}
      <Route path="/terms-and-conditions" element={<TermsPage />} />

      {/* ---Catch All--- */}
      <Route
        path="*"
        element={
          <Navigate
            to={hasDashboardAccess ? '/dashboard' : '/campaigns'}
            replace
          />
        }
      />
    </>
  );

  const highLevelAuthenticatedRoutes = (
    <>
      {/* ---Campaign Terms--- */}
      {(isAdmin || isBountyAdmin) && (
        <>
          <Route
            path="/campaigns/edit/:campaignId/manage-terms"
            element={<CampaignTermsListPage />}
          />
          <Route
            path="/campaigns/edit/:campaignId/manage-terms/:termsId"
            element={<CampaignTermsEditPage />}
          />
          <Route
            path="/settings/manage-terms"
            element={<CampaignTermsListPage />}
          />
          <Route
            path="/settings/manage-terms/:termsId"
            element={<CampaignTermsEditPage />}
          />
        </>
      )}

      {/* ---Users--- */}
      <Route path="/users/*" element={<UsersPage />} />
      <Route path="/users/add/:initialRole" element={<UserEditPage />} />

      {/* ---Codes--- */}
      <Route path="/codes/:providerId" element={<CodesPage />} />

      {/* ---Providers--- */}
      {!isAdvertisingPartner && (
        <>
          <Route path="/offers/*" element={<ProvidersPage />} />
          <Route path="/offers/add" element={<ProviderDetailsPage />} />
          <Route
            path="/offers/edit/:providerId"
            element={<ProviderDetailsPage />}
          />
          <Route
            path="/offer-carousel-images/:providerId"
            element={<ProviderCarouselImagesPage />}
          />
        </>
      )}

      {/* ---Companies--- */}
      {(isAdmin || isBountyAdmin || isCompanyAdmin) && (
        <>
          <Route path="/companies/*" element={<CompaniesPage />} />
          <Route path="/companies/:id" element={<CompanyDetailsPage />} />
        </>
      )}

      {/* ---Connections--- */}
      <Route path="/connections/*" element={<ConnectionsPage />} />
      <Route path="/connections/:id" element={<ConnectionDetailPage />} />

      {/* ---Brands--- */}
      <Route path="/brands" element={<BrandsPage />} />

      {/* ---Settings--- */}
      {(isAdmin || isBountyAdmin) && (
        <Route path="/settings/*" element={<SettingsPage />} />
      )}
    </>
  );

  /**
   * set routes initially to unauthenticated
   */
  let routes = <Routes>{unAuthenticatedRoutes}</Routes>;

  /**
   * authenticated with low level access only (isAdvertiser)
   */
  if (isAuthenticated) {
    routes = (
      <>
        <Navbar />
        <Routes>{lowLevelAuthenticatedRoutes}</Routes>
      </>
    );
  }

  /**
   * authenticated with higher level access (isAdmin || isBountyAdmin || isCompanyAdmin || isCompanyUser)
   */
  if (
    isAuthenticated &&
    (isAdmin || isBountyAdmin || isCompanyAdmin || isCompanyUser)
  ) {
    routes = (
      <>
        <Navbar
          isAdmin={isAdmin}
          isBountyAdmin={isBountyAdmin}
          isCompanyAdmin={isCompanyAdmin}
          isCompanyUser={isCompanyUser}
          isAdvertisingPartner={isAdvertisingPartner}
        />
        <Routes>
          {lowLevelAuthenticatedRoutes}
          {highLevelAuthenticatedRoutes}
        </Routes>
      </>
    );
  }

  /**
   * Email sign in: Set MFA then Password (req firebase flow)
   */
  if (setPassword) {
    routes = (
      <Routes>
        <Route path="/activate" element={<ActivatePage />} />
        <Route path="*" element={<ActivateRedirect />} />
      </Routes>
    );
  }

  if (setResetPassword)
    routes = (
      <Routes>
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="*" element={<Navigate to="/forgot-password" replace />} />
      </Routes>
    );

  /**
   * Normal sign in: Catch non-MFA users
   */
  if (!setPassword && setMultiFactor) {
    routes = (
      <Routes>
        <Route path="/activate-mfa" element={<SetMultiFactorPage />} />
        <Route path="*" element={<SetMultiFactorRedirect />} />
      </Routes>
    );
  }

  return routes;
};

export default MainRoute;
