import {
  useEffect,
  useRef,
  useState,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { useModalConfirm as useConfirmDeleteModal } from '../../components/Modal/ModalConfirm/ModalConfirm';

import ConnectionsService from '../../api/Connections';

const setSessionStorage = (values = {}, key = '') => {
  window.sessionStorage.setItem(`connections-${key}`, JSON.stringify(values));
  window.sessionStorage.setItem(
    `connections-${key}-ts`,
    JSON.stringify(Date.now()),
  );
};

const __DEV__ = process.env.NODE_ENV === 'development';
const consoleKeyDedupe = [];
const getSessionStorage = (key) => {
  /**
   * Session storage can get in the way of
   *  local testing and verification, so we'll avoid
   *  using it altogether whilst in dev
   */
  if (__DEV__) {
    if (!consoleKeyDedupe.includes(key)) {
      // eslint-disable-next-line no-console
      console.warn(`Session storage disabled in dev mode (key: ${key})`);
      try {
        const storageData = JSON.parse(
          window.sessionStorage[`connections-${key}`],
        );
        // eslint-disable-next-line no-console
        console.warn(storageData);
        // eslint-disable-next-line no-empty
      } catch {}
    }
    consoleKeyDedupe.push(key);
    return null;
  }

  try {
    // Ignore session data >1 hour storage
    const ts = JSON.parse(window.sessionStorage[`connections-${key}-ts`]);
    if (ts > Date.now() - 3600000) {
      return null;
    }
    return JSON.parse(window.sessionStorage[`connections-${key}`]);
  } catch {
    return null;
  }
};

const tableFilterReducer = (state, filters, isAdvertiser) => {
  if (isAdvertiser) {
    return null;
  }

  return [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'email', label: 'Email' },
    // Pending implementation on BM-723
    // { key: 'phoneNumber', label: 'Phone' },
  ].map((col) => {
    return {
      key: col.key,
      placeholder: 'Search by ' + col.label,
      label: 'Search by ' + col.label,
      value: filters[col.key],
      type: 'text',
    };
  });
};

/**
 * Filters:
 *  Brands only load if:
 *    (i) a Company filter is selected, or
 *    (ii) Company filter is not applicable (not shown) to current user
 */

const useConnectionsPage = () => {
  const {
    isAdmin,
    isBountyAdmin,
    isCompanyAdmin,
    isCompanyUser,
    isAdvertiser,
  } = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();

  const mountedRef = useRef(true);
  const initOptionsRef = useRef(true);
  const debounceRef = useRef(null);
  const multiQueryRef = useRef(null);
  const multiQueryDebounceRef = useRef(null);

  const [allowListAndDetailView] = useState(isAdmin || isCompanyAdmin);
  const [isDashboardView, setDashboardView] = useState(false); // TODO: Set true on completion
  const [refreshTick, setRefreshTick] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const [showTick, setShowTick] = useState(0);
  const [pendingDeleteCount, setPendingDeleteCount] = useState(0);
  const [isDownloadPending, setDownloadPending] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const [filePassword, setFilePassword] = useState(null);
  const [showFilePasswordModal, setShowFilePasswordModal] = useState(false);

  const [sessionFilters] = useState(getSessionStorage('filters'));
  const [filters, setFilters] = useState({
    firstName: sessionFilters?.firstName ?? '',
    lastName: sessionFilters?.lastName ?? '',
    email: sessionFilters?.email ?? '',
    phoneNumber: sessionFilters?.phoneNumber ?? '',
    ageFrom: sessionFilters?.ageFrom ?? '',
    ageTo: sessionFilters?.ageTo ?? '',
    dateFrom: sessionFilters?.dateFrom ?? '',
    dateTo: sessionFilters?.dateTo ?? '',
    gender: sessionFilters?.gender ?? [],
    city: sessionFilters?.city ?? [],
    country: sessionFilters?.country ?? [],
    company: sessionFilters?.company ?? [],
    brand: sessionFilters?.brand ?? [],
    campaign: sessionFilters?.campaign ?? [],
    dataManagement: sessionFilters?.dataManagement ?? '',
    returningConnections: sessionFilters?.returningConnections ?? false,
    multiQuery: searchParams.get('multi-query') || '',
  });

  const [tableFilters, setTableFilters] = useReducer(
    tableFilterReducer,
    tableFilterReducer(filters, isAdvertiser),
  );

  const [sessionOptions] = useState(getSessionStorage('options'));
  const [filterOptions, setFilterOptions] = useState({
    country: sessionOptions?.country ?? [],
    city: sessionOptions?.city ?? [],
    company: sessionOptions?.company ?? [],
    brand: sessionOptions?.brand ?? [],
    campaign: sessionOptions?.campaign ?? [],
    dataManagement: sessionOptions?.dataManagement ?? [],
  });

  const [filterLoading, setFilterLoading] = useState({
    city: false,
    country: false,
    company: false,
    brand: false,
    campaign: false,
  });

  const loadConnections = () => setRefreshTick((n) => n + 1);

  const resetLoadingFilters = (bool = false) => {
    setFilterLoading((state) => {
      const loadingKeys = Object.keys(state);
      return Object.fromEntries(
        new Map(loadingKeys.map((k) => [k, bool])).entries(),
      );
    });
  };

  const onDownloadReport = async (_filters = {}) => {
    setDownloadPending(true);

    const cleansedFilters = {};
    for (const key in _filters) {
      if (Array.isArray(_filters[key])) {
        cleansedFilters[key] = _filters[key].map((e) => e.value ?? e);
        continue;
      }
      cleansedFilters[key] = _filters[key];
    }

    const password = uuidv4();
    setFilePassword(password);

    ConnectionsService.downloadEncryptedData({
      queryParams: cleansedFilters,
      password,
      onError: () => {
        if (!mountedRef.current) return;
        setDownloadPending(false);
        setToast({
          show: true,
          class: 'error',
          header: 'There was a problem downloading the report please try again',
        });
      },
      onEnd: () => {
        if (!mountedRef.current) return;
        setDownloadPending(false);
        setShowFilePasswordModal(true);
      },
    });
  };

  const updateFilterOptions = (filters_, dataManagement) => {
    const { country, city, company, brand } = filters_;

    setFilterOptions((state) => {
      const filterOptions_ = {
        ...state,
        city: city?.length ? city : state.city,
        country: country?.length ? country : state.country,
        company: company ?? [],
        brand: brand ?? [],
        dataManagement,
      };

      setSessionStorage(filterOptions_, 'options');
      return filterOptions_;
    });
  };

  const noResultMessage = useMemo(() => {
    return !refreshTick
      ? "Please use the Search above and press 'Apply' to see the results."
      : 'No records available';
  }, [refreshTick]);

  const getTableData = useCallback(
    async (config) => {
      const hasSessionCompanyOptions = filterOptions.company.length;
      /* force user to manually submit filters */
      if (!refreshTick && hasSessionCompanyOptions) {
        return {
          data: [],
          count: 0,
        };
      }

      /* debounce while user typing */
      clearTimeout(debounceRef.current);
      await new Promise((resolve) => {
        debounceRef.current = setTimeout(() => {
          resolve();
        }, 300);
      });

      if (initOptionsRef.current) {
        resetLoadingFilters(true);
      }

      setLoading(true);
      const cleansedFilters = {};
      for (const key in filters) {
        if (Array.isArray(filters[key])) {
          cleansedFilters[key] = filters[key].map((e) => e.value ?? e);
          continue;
        }
        cleansedFilters[key] = filters[key];
      }

      const filtersOnly = !refreshTick && !hasSessionCompanyOptions;
      const result = await ConnectionsService.list({
        limit: config.limit,
        page: config.skip / config.limit + 1,
        ...cleansedFilters,
        filtersOnly, // Initial request for filters only
      });

      const connections = result.data?.data?.list ?? [];
      const dataManagement = result.data?.data?.dataManagementOptions ?? [];

      const totalCount_ = result.data?.total_count ?? 0;
      setPendingDeleteCount(result.data?.pending_deletion_count ?? 0);
      setTotalCount(totalCount_);
      setTotalSubmissions(() => {
        const totalSubs = result.data?.data?.list.reduce(
          (curr, acc) => parseInt(acc.campaignsAccepted, 10) + curr,
          null,
        );

        return totalSubs;
      });

      if (initOptionsRef.current) {
        initOptionsRef.current = false;
        const filters_ = result.data.data?.filters ?? {};
        updateFilterOptions(filters_, dataManagement);
        resetLoadingFilters(false);
      } else {
        setFilterOptions((state) => {
          return { ...state, dataManagement };
        });
      }

      setLoading(false);

      return {
        data: connections,
        count: Number(totalCount_),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshTick],
  );

  /**
   * Executes on init
   */
  const getCompanyCityCountryOptions = () => {};

  /**
   * Executes when company or brand changed
   */
  const getBrandCampaignOptions = (companySelections, brandSelections = []) => {
    const noCompanySelection = !companySelections?.length;
    const noBrandSelection = !brandSelections?.length;
    if (noCompanySelection && noBrandSelection) {
      return; // Skip filter options update
    }

    setFilterLoading((state) => ({
      ...state,
      brand: true,
      campaign: true,
    }));

    ConnectionsService.filterOptions({
      company: (companySelections ?? []).map((e) => e.value),
      brand: (brandSelections ?? []).map((e) => e.value),
    })
      .then((response) => {
        if (!mountedRef.current) return;

        setFilterOptions((state) => {
          const newState = {
            ...state,
            /**
             * Only update brands if one wasn't already selected
             *  else brand options could be cleared
             */
            ...(!brandSelections?.length && {
              brand: response.data?.brand ?? [],
            }),
            campaign: response.data?.campaign ?? [],
          };
          setSessionStorage(newState, 'options');
          return newState;
        });

        setFilterLoading((state) => ({
          ...state,
          brand: false,
          campaign: false,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFilterUpdated = (filterKey, value) => {
    setFilters((state) => {
      const newValue = Array.isArray(state[filterKey]) && !value ? [] : value;
      const newState = {
        ...state,
        [filterKey]: newValue,
      };

      if (filterKey === 'company' && !newValue?.length) {
        newState.brand = [];
        newState.campaign = [];
      }

      if (filterKey === 'brand' && !newValue?.length) {
        newState.campaign = [];
      }

      setSessionStorage(newState, 'filters');
      return newState;
    });

    /** Reset brands (only) if company removed */
    if (filterKey === 'company' && !value?.length) {
      setFilterOptions((state) => ({
        ...state,
        brand: [],
      }));
    }
  };

  const onResetAllFilters = () => {
    setSearchParams({});
    setFilters((state) => {
      setSessionStorage(null, 'filters');
      const filterKeys = Object.keys(state);
      const getEmptyValue = (value) => {
        if (typeof value === 'string') return '';
        if (typeof value === 'boolean') return false;
        if (Array.isArray(value)) return [];
        return null;
      };

      return Object.fromEntries(
        new Map(filterKeys.map((k) => [k, getEmptyValue(state[k])])).entries(),
      );
    });
  };

  const [confirmDeleteProps, setConfirmDeleteProps] = useConfirmDeleteModal({
    title: 'Delete PII?',
    supplementalErrorKey: 'records',
    apiRequest: () => {},
  });

  const openDeleteConnectionModal = ({ id: cpdcId }) => {
    setConfirmDeleteProps({
      cancelText: 'Cancel',
      saveText: 'Delete PII',
      apiId: cpdcId,
      apiRequest: ConnectionsService.deletePii,
      onSuccess: loadConnections,
    });
  };

  /**
   * Autoload on multiQuery change
   *  but do not auto load on page load
   *  if query string empty
   *
   * AUTO SEARCH DISABLED
   * Moved into search panel (only)
   * https://bountymedia.atlassian.net/browse/BM-713?focusedCommentId=11293
   */
  // useEffect(() => {
  //   clearTimeout(multiQueryDebounceRef.current);
  //   if (filters.multiQuery || multiQueryRef.current) {
  //     multiQueryDebounceRef.current = setTimeout(() => loadConnections(), 750);
  //   }

  //   multiQueryRef.current = filters.multiQuery;
  // }, [filters.multiQuery]);

  useEffect(() => {
    setTableFilters(filters, isAdvertiser);
  }, [filters, isAdvertiser]);

  useEffect(() => {
    getBrandCampaignOptions(filters.company, filters.brand);
  }, [filters.company, filters.brand]);

  useEffect(() => {
    getCompanyCityCountryOptions();
  }, []);

  useEffect(
    () => () => {
      clearTimeout(debounceRef.current);
      mountedRef.current = false;
    },
    [],
  );

  const getters = {
    allowListAndDetailView,
    isDashboardView,
    isAdmin,
    isBountyAdmin,
    isCompanyAdmin,
    isCompanyUser,
    filters,
    filterOptions,
    filterLoading,
    tableFilters,
    totalCount,
    totalSubmissions,
    pendingDeleteCount,
    isDownloadPending,
    toast,
    filePassword,
    showFilePasswordModal,
    confirmDeleteProps,
    noResultMessage,
    isLoading,
    showTick,
  };

  const actions = {
    onFilterUpdated,
    onDownloadReport,
    onResetAllFilters,
    setDashboardView,
    closeToast,
    getTableData,
    setFilePassword,
    loadConnections,
    setShowFilePasswordModal,
    openDeleteConnectionModal,
    setShowTick,
  };

  return [getters, actions];
};

export default useConnectionsPage;
