import React, { Fragment, useEffect, useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useLogout } from '../../App.logout';

import AccountService from '../../api/Account';
import UserService from '../../api/Users';

import * as actions from '../../store/actions/auth';
import packageInfo from '../../../package.json';
import bountyLogo from '../../assets/images/bounty-logo-sm.png';
import { formatDate } from '../../shared/dateTime';
import useScrollEvent from '../../hooks/useScrollEvent';

import './Navbar.scss';

const ProfileIcon = ({ src }) => {
  return src ? (
    <img src={src} className="profile-icon" alt="Profile icon" />
  ) : null;
};

const NavbarComponent = ({
  isAdmin,
  isBountyAdmin,
  isCompanyAdmin,
  isCompanyUser,
  isAdvertisingPartner,
  isAdvertiser,
}) => {
  const logout = useLogout();
  const scrollTop = useScrollEvent();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userId, lastSignInTime, profileImgUrl } = useSelector(
    (state) => state.auth,
  );
  const [userLogs, setUserLogs] = useState([]);
  const [currentPathName, setCurrentPathName] = useState(pathname);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const profilePhotoImgUrl = profileImgUrl
    ? `${process.env.REACT_APP_UPLOADS_URL}/${profileImgUrl}`
    : '';

  const getUserLogs = async () => {
    const res = await AccountService.getUserLogs();
    if (res) {
      setUserLogs(res.data);
    }
  };

  useEffect(() => {
    getUserLogs();
  }, [toggleDropdown]);

  useEffect(() => {
    setCurrentPathName(pathname);
  }, [pathname]);

  const onLogout = () => {
    logout();
  };

  const handleToggleDropdown = () => {
    setToggleDropdown(!toggleDropdown);
  };

  const navClass =
    Math.ceil(scrollTop) > 35
      ? 'bm-navbar-main px-3'
      : 'bm-navbar-main px-3 shadow';

  const hasUserLogs = !!userLogs.length;

  return (
    <Navbar bg="light" expand="md" className={navClass} sticky="top">
      <Navbar.Brand as={Link} to="/">
        <img src={bountyLogo} alt="Bounty Media logo" />
        <span className="app-version">{`v${packageInfo.version}`}</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link
            as={Link}
            to="/campaigns"
            className={
              currentPathName.startsWith('/campaigns') && 'active-link'
            }
          >
            Campaigns
          </Nav.Link>
          <Fragment>
            {(isAdmin || isCompanyAdmin) && (
              <Nav.Link
                as={Link}
                to="/connections"
                data-test-id="connections-list-link"
                className={
                  currentPathName.startsWith('/connections') && 'active-link'
                }
              >
                Connections
              </Nav.Link>
            )}
            {(isAdmin || isBountyAdmin || isCompanyUser || isCompanyAdmin) && (
              <Nav.Link
                as={Link}
                to="/users"
                data-test-id="users-list-link"
                className={
                  currentPathName.startsWith('/users') && 'active-link'
                }
              >
                Users
              </Nav.Link>
            )}
            {(isAdmin || isBountyAdmin || isCompanyAdmin) && (
              <Nav.Link
                as={Link}
                to="/companies"
                data-test-id="companies-list-link"
                className={
                  currentPathName.startsWith('/companies') && 'active-link'
                }
              >
                {isCompanyAdmin ? 'Company Profile' : 'Companies'}
              </Nav.Link>
            )}
            {(isAdmin || isBountyAdmin || isCompanyAdmin || isCompanyUser) && (
              <Nav.Link
                as={Link}
                to="/brands"
                data-test-id="brands-list-link"
                className={
                  currentPathName.startsWith('/brands') && 'active-link'
                }
              >
                Brands
              </Nav.Link>
            )}

            {(isAdmin ||
              isBountyAdmin ||
              ((isCompanyAdmin || isCompanyUser) && !isAdvertisingPartner)) && (
              <Nav.Link
                as={Link}
                to="/offers"
                data-test-id="offers-link"
                className={
                  currentPathName.startsWith('/offers') && 'active-link'
                }
              >
                Offers
              </Nav.Link>
            )}
            {(isAdmin || isBountyAdmin) && (
              <Nav.Link
                as={Link}
                to="/settings"
                data-test-id="settings-link"
                className={
                  currentPathName.startsWith('/settings') && 'active-link'
                }
              >
                Settings
              </Nav.Link>
            )}
          </Fragment>
          <NavDropdown
            title={
              profileImgUrl ? (
                <ProfileIcon src={profilePhotoImgUrl} />
              ) : (
                <svg
                  width="24"
                  height="20"
                  viewBox="0 0 24 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0C5.37267 0 0 5.37267 0 12C0 15.0318 1.12403 17.8009 2.97876 19.9129C4.89094 16.9563 8.21688 14.9999 12 14.9999C15.7831 14.9999 19.1091 16.9563 21.0217 19.9124C22.876 17.8004 24 15.0313 24 12C24 5.37267 18.6278 0 12 0ZM12 13.5002C9.29672 13.5002 7.10562 11.3086 7.10562 8.60531C7.10562 5.90203 9.29672 3.71044 12 3.71044C14.7033 3.71044 16.8949 5.90203 16.8949 8.60531C16.8949 11.3086 14.7033 13.5002 12 13.5002Z"
                    fill="#FF6F00"
                  />
                </svg>
              )
            }
            id="basic-nav-dropdown"
            onClick={handleToggleDropdown}
          >
            <NavDropdown.Item
              as={Link}
              to={`/users/edit/${userId}`}
              data-test-id="profile-button"
            >
              Profile
            </NavDropdown.Item>
            {(isAdmin || isBountyAdmin) && (
              <NavDropdown.Item as={Link} to="/settings/manage-terms">
                Manage Terms
              </NavDropdown.Item>
            )}
            <NavDropdown.Item onClick={onLogout} data-test-id="logout-button">
              Logout
            </NavDropdown.Item>
            <div className="profile-info">
              <span>{window.sessionStorage.getItem('auth-email')}</span>
              <span>Last login at:</span>
              <span>
                {formatDate(new Date(lastSignInTime), 'dd MMM yyyy HH:mm')}
              </span>
            </div>
            {hasUserLogs && (
              <div className="profile-activity-info">
                <p>Last 3 Activities:</p>
                {userLogs.map((userLog) => {
                  return (
                    <span key={userLog.id}>
                      {userLog.moduleType} ({userLog.moduleName}){' '}
                      {formatDate(userLog.timestamp)}
                    </span>
                  );
                })}
              </div>
            )}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
