import React from 'react';
import Col from 'react-bootstrap/Col';
import { useSearchParams } from 'react-router-dom';

import {
  faSearch,
  faEllipsisH,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Dropdown from 'react-bootstrap/Dropdown';
import ActionsNavbar from '../ActionsNavbar/ActionsNavbar';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';

import styles from './ConnectionsNavBar.module.scss';

const ConnectionsNavBar = ({
  isDashboardView,
  setDashboardView,
  filters,
  setShowTick: setShowFiltersTick,
  onFilterUpdated,
  totalCount,
  isAdmin,
  isCompanyAdmin,
  onDownloadReport,
  isDownloadPending,
  onResetAllFilters,
}) => {
  const [, setSearchParams] = useSearchParams();

  const updateFilter = (key, ev) => {
    const value = ev?.target?.value ?? ev?.value ?? ev;
    onFilterUpdated(key, value);

    if (key === 'multiQuery') {
      setSearchParams({ 'multi-query': value });
    }
  };

  const hasResults = Number(totalCount);

  return (
    <ActionsNavbar variant="light">
      <Col className="d-flex justify-content-start flex-wrap align-content-start col-lg-3">
        <h1 className="mt-2">Connections</h1>
      </Col>

      <Col className="d-flex justify-content-end justify-content-sm-end flex-wrap align-content-center">
        <Button
          additionalClasses="btnFilter px-4"
          onClick={() => setShowFiltersTick((n) => n + 1)}
          // disabled={!Object.keys(selectedRow).length}
        >
          <FontAwesomeIcon icon={faSearch} className={styles.icon} />
          <span className="ms-3">Search</span>
        </Button>

        <Button
          additionalClasses="btnDefault px-4"
          onClick={() => onDownloadReport(filters)}
          disabled={!(isAdmin || isCompanyAdmin)}
          data-test-id="connections-download-button"
        >
          <span>Download Connections</span>
        </Button>
      </Col>
    </ActionsNavbar>
  );
};

export default ConnectionsNavBar;
