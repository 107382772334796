import instance from './Api';
import fileService from './File';

const list = (queryParams = {}) => {
  const query = new URLSearchParams({
    ...queryParams,
    limit: queryParams.limit || 20,
    order: 'createdAt',
    order_direction: 'desc',
  }).toString();
  return instance.get(`/connections?${query}`);
};

const detail = (cpdcId) => instance.get('/connections/detail/' + cpdcId);

const pendingDeleteCount = () => instance.get('/connections/pending-delete');

const filterOptions = (queryParams = {}) => {
  const query = new URLSearchParams(queryParams).toString();
  return instance.get(`/connections/filters?${query}`);
};

const deletePii = (cpdcId) => instance.delete('/connections/' + cpdcId);

const downloadEncryptedData = ({
  queryParams = {},
  password,
  onError,
  onEnd,
}) => {
  const query = new URLSearchParams({ ...queryParams, password }).toString();
  fileService.download({
    url: `/connections/generate-csv?${query}`,
    fileName: 'connections-data',
    fileType: 'zip',
    onError,
    onEnd,
  });
};

export default {
  list,
  detail,
  deletePii,
  pendingDeleteCount,
  filterOptions,
  downloadEncryptedData,
};
